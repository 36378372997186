<template>
  <v-container
    id="services"
    fluid
    tag="section"
  >
    <base-material-card
      title="Liste des Mesures"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              Nom
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="service in services"
            :key="service.id"
          >
            <td>{{ service.id }}</td>
            <td>{{ service.name }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import gql from 'graphql-tag'

  export default {
    name: 'Services',
    data () {
      return {
        services: [],
      }
    },
    watch: {
      '$route.name': {
        handler () {
          this.$nextTick(() => this.$apollo.queries.services.refetch())
        },
        deep: true,
        immediate: true,
      },
    },
    apollo: {
      services: {
        query: gql`
          query {
            services (sort: ["sort", "name"]) {
              id
              name
            }
          }
        `,
        error (error) {
          const code = error.networkError?.statusCode
          if (code === 400 || code === 401) {
            localStorage.removeItem('jwt')
            this.$router.push({ name: 'Login' })
          }
        },
      },
    },
  }
</script>

<style scoped>

</style>
